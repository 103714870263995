/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// import $ from 'jquery';
// window.$ = window.jQuery = $;

// import 'popper.js';  // Popper.js is required before Bootstrap
// import 'bootstrap';   // Bootstrap depends on Popper.js and jQuery

// import 'eonasdan-bootstrap-datetimepicker';
// import 'moment'; // Required for datetimepicker to work properly

require('./bootstrap');
window.Vue = require('vue');
window.VueResource = require('vue-resource');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding admin to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


//use an empty Vue instance as a central event bus to do non parent-child coummunication
window.bus = new Vue();

if(document.querySelector('input[name="_token"]')){
    window.Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('input[name="_token"]').value;
}else{
    window.Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name="csrf-token"]').content;
}

// import babel from 'babel-polyfill'; //IE
import babel from '@babel/polyfill'; //IE

import registrationComponent from './vue-components/registration/registration.vue';

var myMixin = {
    methods: {
        groupBy: function (collection, property, fieldName) {
            var i = 0, val, index,
                values = [], result = [];
            for (i=0; i < collection.length; i++) {
                val = collection[i][property].toString();
                index = values.indexOf(val);
                if (index > -1)
                    result[index][fieldName].push(collection[i]);
                else {
                    values.push(val);

                    //result.push([collection[i]]);
                    result.push([]);
                    index = values.length -1;

                    result[index][fieldName]=[];
                    result[index][fieldName].push(collection[i]);
                    result[index][property]=val;
                }
            }
            return result;
        },
        getIndexByFieldValue: function (inputArr, field, value) {
            //find the element index that has field=value from inputArr
            if (inputArr.length > 0) {
                var index;
                for (index = 0; index < inputArr.length; index++) {
                    if (inputArr[index][field] == value) {
                        return index;
                    }
                }
            }
            return -1;
        },
        removeFromArray: function (inputArr, field, value) {
            //remove the element that has field=value from inputArr
            if (inputArr.length > 0) {
                var index;
                for (index = 0; index < inputArr.length; index++) {
                    if(typeof inputArr[index]['children']!='undefined' && inputArr[index]['children'].length>0)
                    {
                        this.removeFromArray(inputArr[index]['children'], field, value);
                    }
                    if (inputArr[index][field] == value) {
                        inputArr.splice(index, 1);
                    }
                }
            }
            return inputArr;
        },
        sortBy: function (collection, field, direction) {
            collection.sort(function(a, b){
                var result=0;
                var itemA = isNaN(a[field])&&typeof a[field]!='undefined' ? a[field].toLowerCase(): a[field];
                var itemB = isNaN(b[field])&&typeof b[field]!='undefined' ? b[field].toLowerCase(): b[field];

                if(direction=='asc')
                {
                    //sort string ascending
                    result = (itemA<itemB)? -1: 1;
                }else if(direction=='desc')
                {
                    result = (itemA<itemB)? 1: -1;
                }

                return result;
            });
            return collection;
        },
        shuffle: function(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        }
    }
}


var lmsVue = new Vue(
    {
        el: '#home_section',

        components: {
            babel,

            registrationComponent,


        },
        mixins:[myMixin],
    }
);

$(function () {
    $('.perfect-scrollbar-basic').perfectScrollbar();
    $('.perfect-scrollbar-scroll-y').perfectScrollbar({
        suppressScrollX: true
    });

    $(window).on('resize', function() {
        $('.perfect-scrollbar-basic').perfectScrollbar('update');
        $('.perfect-scrollbar-scroll-y').perfectScrollbar('update');
    });
});
